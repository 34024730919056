import React, { Fragment, useState, useEffect } from 'react'

import IProps from './interface'
import {
  IResultItem,
  ISearchResponse,
  ISortItem,
  IFacet,
  IFacetFilter,
  ISearchBody,
} from '../../Utils/interface'
import {
  defaults,
  END_POINTS,
  dataFetcher,
  validateData,
  controlZIndexes,
} from '../../Utils/utils'
import BlockWrapper from '../../../Layout/BlockWrapper/BlockWrapper'
import Title from '../../../Element/Title/Title'
import ResultsCount from '../../Element/ResultsCount/ResultsCount'
import CustomSelect from '../../Element/CustomSelect/CustomSelect'
import FiltersMenu from '../../Element/FiltersMenu/FiltersMenu'
import ResultCard from '../../Element/ResultCard/ResultCard'
import QuickFilterButton from '../../Element/QuickFilterButton/QuickFilterButton'
import Loading from '../../Element/Loading/Loading'
import { IClearFlag } from '../../Element/FiltersMenu/interface'
import { IOption } from '../../Element/CustomSelect/interface'

const CourseTable = (props: IProps) => {
  const {
    pageId = '',
    baseURL = '',
    defaultFilters = [],
    title,
    isCourseSearch = true,
    ...rest
  } = props

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [loadMore, setLoadMore] = useState(false)
  const [results, setResults] = useState<IResultItem[] | undefined>()
  const [pagination, setPagination] = useState<
    ISearchResponse['pagination'] | undefined
  >()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentSearch, setCurrentSearch] = useState<ISearchBody | undefined>()
  const [sortOptions, setSortOptions] = useState<ISortItem[] | undefined>()
  const [studentTypes, setStudentTypes] = useState<IOption[] | undefined>()
  const [facets, setFacets] = useState<IFacet[] | undefined>()
  const [clearFlag, setClearFlag] = useState<IClearFlag>()
  const [selectedFacets, setSelectedFacets] = useState<
    IFacet['value'][] | undefined
  >()
  const [quickFilters, setQuickFilters] = useState<IFacetFilter[] | undefined>()
  const [previousSearch, setPreviousSearch] = useState<
    ISearchBody | undefined
  >()
  const [isLastPage, setIsLastPage] = useState(false)
  // This will help maintain filters menu accordion items open/close while interacting with filters.
  const [openFacets, setOpenFacets] = useState<string[] | undefined>()

  const defaultSearch: ISearchBody = {
    pageId,
    Group: isCourseSearch ? defaults.groupCourses : defaults.group,
    Page: 1,
    pageSize: defaults.pageSize,
    sortType: '',
    sortValue: '',
    Filters: [...defaultFilters],
  }

  /**
   * This actually sends the search request and populates the variables with the response.
   * @param searchBody object
   */
  const proceedToSearch = async (searchBody: ISearchBody) => {
    if (
      !previousSearch ||
      JSON.stringify(previousSearch) !== JSON.stringify(searchBody)
    ) {
      // This test prevents request loop when no change is actively made
      setPreviousSearch(searchBody)
      if (!loadMore) {
        setLoading(true)
        setResults(undefined)
      }

      const response: ISearchResponse = await dataFetcher(
        isCourseSearch
          ? `${baseURL}${END_POINTS.courses}`
          : `${baseURL}${END_POINTS.search}`,
        searchBody
      )
      if (response?.results) {
        const validData = validateData(response)
        if (validData.log.length > 0) {
          console.log(validData.log)
        }

        setError(false)

        if (loadMore) {
          setResults((results) => {
            setLoadMore(false)
            results?.push(...response.results)
            return results
          })
        } else {
          setResults(response.results)
        }
        setStudentTypes(prepareStudentTypes(response.categorisation.facets))
        setFacets(prepareFacets(response.categorisation.facets))
        setSortOptions(response.categorisation.sortItems)
        setCurrentPage(response.pagination.currentPage)
        setPagination(response.pagination)
        setIsLastPage(
          (response.pagination?.currentPage || 1) >=
            (response.pagination?.totalPages || 1)
        )
        setLoading(false)
      } else {
        setError(true)
        setLoading(false)
      }
    }
  }

  const studentType = 'StudentType'
  const prepareFacets = (bulkFacets: IFacet[]) => {
    return isCourseSearch
      ? bulkFacets.filter((facet) => facet.value !== studentType)
      : bulkFacets
  }

  const prepareStudentTypes = (bulkFacets: IFacet[]) => {
    const matchingFacets = bulkFacets.filter(
      (facet) => facet.value === studentType
    )
    if (matchingFacets.length === 0) {
      // Handle the case where no facets match the studentType
      return undefined
    }

    const tempFilters = matchingFacets[0].filters

    return tempFilters.map((tempFilter) => ({
      name: tempFilter.name,
      selected: tempFilter.selected,
      sortType: 'studentType',
      sortValue: tempFilter.value,
    }))
  }

  const prepareSortOptions = (bulkSortOptions: ISortItem[]) => {
    return bulkSortOptions.map((sortOption) => ({
      ...sortOption,
      value: sortOption.sortValue,
    }))
  }

  /**
   * Creates new body request with new facets and sorting options
   * @param facets string[]
   * @param sortType string
   * @param sortValue string
   */
  const facetsAndSortCallback = (
    facets: string[],
    sortType: string,
    sortValue: string
  ) => {
    if (currentSearch) {
      const body: ISearchBody = {
        ...currentSearch,
        Page: defaultSearch.Page,
        Filters: facets,
        sortType: sortType || currentSearch.sortType,
        sortValue: sortValue || currentSearch.sortValue,
      }
      setSelectedFacets(facets)
      setCurrentSearch(body)
    }
  }

  /**
   * Creates new body request with new sorting options
   * @param props object
   */
  const studentTypesCallback = (props: { sortValue: string }) => {
    const studentTypesList = studentTypes?.map(
      (studentType) => studentType.sortValue
    )
    const initialFilters = currentSearch?.Filters || []
    const cleanFilters = initialFilters.filter(
      (initialFilter) => !studentTypesList?.includes(initialFilter)
    )
    if (currentSearch) {
      const { sortValue } = props
      cleanFilters.push(sortValue)
      const body = {
        ...currentSearch,
        Filters: cleanFilters,
      }
      setCurrentSearch(body)
    }
  }

  /**
   * Creates new body request with new sorting options
   * @param props object
   */
  const sortCallback = (props: { sortType: string; sortValue: string }) => {
    if (currentSearch) {
      const { sortType, sortValue } = props
      const body = {
        ...currentSearch,
        Page: defaultSearch.Page,
        sortType,
        sortValue,
      }
      setCurrentSearch(body)
    }
  }

  /**
   * Creates new body request with new page number
   * @param Page number
   */
  const loadMoreCallback = (Page: number) => {
    if (currentSearch) {
      const body = {
        ...currentSearch,
        Page,
      }
      setLoadMore(true)
      setCurrentSearch(body)
    }
  }

  /**
   * Sends message to remove the provided "Quick filter"
   * @param value string
   */
  const quickFiltersCallback = (value: string) => {
    setClearFlag({
      filter: value,
      status: true,
    })
    setTimeout(() => setClearFlag(defaults.clearFlag), 200)
  }

  /**
   * Each time the current search body is updated by one of the previous callback functions,
   *  - if the search parameters are different from the previous search
   *  - then proceed to search.
   */
  useEffect(() => {
    if (!currentSearch) {
      setCurrentSearch(defaultSearch)
    } else {
      if (JSON.stringify(previousSearch) !== JSON.stringify(currentSearch)) {
        // This test prevents request loop when no change is actively made
        proceedToSearch(currentSearch)
      }
    }
  }, [currentSearch])

  /**
   * This updates the list of Quickfilters depending on the filters selected in the Filters menu
   */
  useEffect(() => {
    const selection = facets?.map((facet) => {
      return facet.filters.filter((filter) =>
        selectedFacets?.includes(filter.value)
      )
    })

    setQuickFilters(selection?.flat())
  }, [selectedFacets])

  useEffect(() => {
    controlZIndexes()
  }, [])

  return (
    <BlockWrapper {...rest}>
      <div className='searchResults__inner'>
        <Title type='h2' classes='courseList__title'>
          {title}
        </Title>
        {!loading && sortOptions ? (
          <div className='searchResults__filters-wrapper'>
            {facets ? (
              isCourseSearch ? (
                <FiltersMenu
                  facets={facets}
                  openFacets={openFacets}
                  openFacetsCallBack={setOpenFacets}
                  sortItems={prepareSortOptions(sortOptions)}
                  buttonLabel={'Filters'}
                  clearAll
                  callBack={facetsAndSortCallback}
                  clearFlag={clearFlag}
                />
              ) : (
                <FiltersMenu
                  facets={facets}
                  openFacets={openFacets}
                  openFacetsCallBack={setOpenFacets}
                  buttonLabel={'Filters'}
                  clearAll
                  callBack={facetsAndSortCallback}
                  clearFlag={clearFlag}
                />
              )
            ) : null}
            {isCourseSearch && studentTypes ? (
              <CustomSelect
                name='studentType'
                label='Select student type'
                prefix=''
                options={studentTypes}
                callBack={studentTypesCallback}
              />
            ) : (
              <CustomSelect
                {...defaults.sortOptions}
                options={sortOptions}
                callBack={sortCallback}
              />
            )}
            {facets ? (
              <div className='searchResults__quick-filter-wrapper'>
                {quickFilters?.map(({ name, value }) => (
                  <Fragment key={value}>
                    <QuickFilterButton
                      text={name}
                      callBack={() => quickFiltersCallback(value)}
                    />
                  </Fragment>
                ))}
                {quickFilters && quickFilters?.length > 0 ? (
                  <button
                    className='button button__type--tertiary button__size--base searchResults__clear-filters'
                    onClick={(e) => {
                      e.preventDefault()
                      setClearFlag({
                        ...defaults.clearFlag,
                        status: true,
                      })
                      setTimeout(() => setClearFlag(defaults.clearFlag), 500)
                    }}>
                    Clear all <span className='sr-only'>filters</span>
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className='searchResults' aria-live='polite'>
          {loading && !results ? (
            <Loading text='Courses loading...' />
          ) : (
            <>
              {error ? <p>{defaults.error}</p> : null}
              {currentSearch && results && results?.length > 0 ? (
                <>
                  <ResultsCount
                    totalItems={pagination?.totalItems || 0}
                    pageSize={pagination?.pageSize || defaults.pageSize}
                    currentPage={currentPage}
                  />
                  <ul className='searchResults__cards-wrapper'>
                    {results.map((result, index) => {
                      return (
                        <li key={index}>
                          <ResultCard {...result} uid={index.toString()} />
                        </li>
                      )
                    })}
                  </ul>
                  {loadMore && <Loading text='Results loading...' />}
                  {!isLastPage ? (
                    <div className='searchResults__pagination-wrapper'>
                      <button
                        className='button link__color--primary button__type--secondary button__size--base'
                        onClick={() => loadMoreCallback(currentPage + 1)}>
                        Load more
                      </button>
                    </div>
                  ) : null}
                </>
              ) : (
                !error &&
                currentSearch && (
                  <ResultsCount
                    totalItems={pagination?.totalItems || 0}
                    pageSize={pagination?.pageSize || defaults.pageSize}
                    currentPage={currentPage}
                  />
                )
              )}
            </>
          )}
        </div>
      </div>
    </BlockWrapper>
  )
}

export default CourseTable
